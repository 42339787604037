// import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { appConfig } from "../../appConfig";

export const GoogleTracking = (params) => {
  if (appConfig.googleTrackingId && typeof params.ga_data != 'undefined') {
    switch (params.ga_data.type) {
      case "event":
        ReactGA.event(params.ga_data.data);
        break;
      case "pageview":
        ReactGA.pageview(params.ga_data.data.path);
        break;
      default:
        return;
    }
  }

  // if (appConfig.googleTagManagerId) {
  //   let tagManagerArgs = {
  //     dataLayer: params.dataLayer,
  //     dataLayerName: params.dataLayerName,
  //   };

  //   TagManager.dataLayer(tagManagerArgs);
  // }
};
