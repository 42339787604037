import axios from "axios";
import { appConfig } from "../appConfig";
import { GoogleTracking } from "../components/google/GoogleTracking";

export const AppService = {
  initialize,
  getAboutUsData,
  getSupportPageData,
  getBlogDetails,
  setMetaData,
  getCauseDetails,
  getTeamMemberDetails,
  getGalleryData,
};

function initialize() {
  GoogleTracking({
    ga_data: {
      type: "pageview",
      data: {
        path: window.location.pathname + window.location.search,
      },
    },
  });
  return axios
    .post(`${appConfig.gatewayRootUrl}/initialize`, {})
    .then((res) => {
      let appData = {};

      try {
        appData = {
          languages: res.data.data.languages,
          currencies: res.data.data.currencies,
          blogs: res.data.data.blogs,
          gallery_count: res.data.data.gallery_count,
          categories: res.data.data.categories,
          open_causes: res.data.data.open_causes,
          settings: res.data.data.settings,
          pageContents: res.data.data.page_contents,
          loggedInUser: res.data.user,
        };

        if (res.data.user) {
          const token = res.data.user["access_token"];
          const userInfo = {
            user_id: res.data.user.user_id,
            user_username: res.data.user.user_username,
            user_full_name: res.data.user.user_full_name,
            user_img: res.data.user.user_img,
            session_expires_at: res.data.user.session_expires_at,
          };
          localStorage.setItem(appConfig.userToken, token);
          localStorage.setItem(appConfig.userInfo, JSON.stringify(userInfo));
        }
      } catch (error) {
        console.log(error);
      }

      return appData;
    });
}

function getAboutUsData() {
  return axios
    .get(`${appConfig.gatewayRootUrl}/about-data`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      let aboutData = {};

      try {
        aboutData = {
          main_header_img: res.data.data.main_header_img,
          meta: res.data.data.meta,
          headers: res.data.data.headers,
          pillars: res.data.data.pillars,
          teams: res.data.data.teams,
        };
      } catch (error) {
        console.log(error);
      }

      return aboutData;
    });
}

function getSupportPageData(page_code) {
  return axios
    .get(`${appConfig.gatewayRootUrl}/support-page/${page_code}`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      let supportPageData = {};

      try {
        supportPageData = {
          success: res.data.success,
          main_header_img: res.data.main_header_img,
          meta: res.data.meta,
          headers: res.data.headers,
          pillars: res.data.pillars,
        };
      } catch (error) {
        console.log(error);
      }

      return supportPageData;
    });
}

function getBlogDetails(unique_title) {
  return axios
    .get(`${appConfig.gatewayRootUrl}/blogs/${unique_title}`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      let blogDetails = {};

      try {
        blogDetails = {
          success: res.data.success,
          blog: res.data.blog,
          meta: res.data.meta,
          headers: res.data.headers,
          pillars: res.data.pillars,
        };
      } catch (error) {
        console.log(error);
      }

      return blogDetails;
    });
}

function getTeamMemberDetails(unique_title) {
  return axios
    .get(`${appConfig.gatewayRootUrl}/team-member/${unique_title}`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      let teamMemberDetails = {};

      try {
        teamMemberDetails = {
          success: res.data.success,
          teamMember: res.data.teamMember,
          meta: res.data.meta,
        };
      } catch (error) {
        console.log(error);
      }

      return teamMemberDetails;
    });
}

function setMetaData(meta) {
  if (typeof meta != "undefined") {
    if (meta.title) {
      document.title = meta.title;
    }
    if (meta.description) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", meta.description);
    }
    if (meta.keywords) {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", meta.keywords);
    }
  } else {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", appConfig.appMeta.main.description);
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", appConfig.appMeta.main.keywords);
  }
}

function getCauseDetails(unique_title) {
  return axios
    .get(`${appConfig.gatewayRootUrl}/cause/${unique_title}`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      let causeDetails = {};

      try {
        causeDetails = {
          success: true,
          cause: res.data.cause,
          meta: res.data.meta,
          headers: res.data.headers ? res.data.headers : [],
          pillars: res.data.pillars ? res.data.pillars : [],
        };
      } catch (error) {
        console.log(error);
      }

      return causeDetails;
    });
}

function getGalleryData(locale) {
  return axios
    .get(`${appConfig.gatewayRootUrl}/media/gallery?locale=${locale}`, {
      headers:
        typeof appConfig.gatewayHeaders != "undefined"
          ? appConfig.gatewayHeaders
          : {},
    })
    .then((res) => {
      return res.data?.gallery || [];
    });
}