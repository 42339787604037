import { ajaxRequestsConstants } from "./constants"

export const addRequest = (key, description) => {
  return dispatch => {
    dispatch({ type: ajaxRequestsConstants.ADD_REQUEST, payload :{ key, description } })
  }
}

export const removeRequest = (key) => {
  return dispatch => {
    dispatch({ type: ajaxRequestsConstants.REMOVE_REQUEST, payload :{ key } })
  }
}