import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { mainReducer } from "./reducers/mainReducer";
import saaReducer from "@soiswis/saa-react-redux-search-component/lib/reducers/saaReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const middleware = applyMiddleware(thunkMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  combineReducers({
    main: mainReducer,
    toastr: toastrReducer,
    saaReducer,
  }),
  {},
  composeEnhancers(middleware)
);
