import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
// import TagManager from "react-gtm-module";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import "./assets/scss/styles.scss";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { store } from "./redux";
import { appConfig } from "./appConfig";

if (appConfig.googleTrackingId) {
  ReactGA.initialize(appConfig.googleTrackingId);
}

// if (appConfig.googleTagManagerId) {
//   const tagManagerArgs = {
//     gtmId: appConfig.googleTagManagerId,
//   };

//   TagManager.initialize(tagManagerArgs);
// }

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-center"
      getState={(state) => state.toastr} // This is the default
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
