export const appMainConstants = {
    SET_IS_INITIALISED: 'SET_IS_INITIALISED'
}
export const preloaderConstants = {
    SHOW_INITIALISING_PRELOADER: 'SHOW_INITIALISING_PRELOADER',
    HIDE_INITIALISING_PRELOADER: 'HIDE_INITIALISING_PRELOADER'
};

export const appDataConstants = {
    DATA_SUCCESS: 'DATA_SUCCESS',
    DATA_FAILURE: 'DATA_FAILURE'
};

export const ajaxRequestsConstants = {
    ADD_REQUEST: 'ADD_REQUEST',
    REMOVE_REQUEST: 'REMOVE_REQUEST'
};