export let appConfig = {
  environment: "dev",
  version: "1.0.0",
  colors: {
    primary: "#000000",
    secondary: "#c71c69",
  },
  appMainTitle: document.title,
  appMeta: {
    main: {
      keywords: document
        .querySelector('meta[name="keywords"]')
        .getAttribute("content"),
      description: document
        .querySelector('meta[name="description"]')
        .getAttribute("content"),
    },
  },
  securePathPrefix: "/account/",
  userToken: "saa_token",
  userInfo: "saa_user",
  gatewayRootUrl: "http://127.0.0.1:8000/api",
  googleReCaptcha: {
    sitekey: "6LfBwDIaAAAAAAT6sDPrPDliqM9NdLRCd6oD_cVj",
  },
  googleTrackingId: null,
  googleTagManagerId: null,
  googleMapAPIKey: "AIzaSyBxLxSIy0ejUxUqn4dc7g4RollHGwPsJlE",
  mainAlert: {
    position: "top-center",
    timeout: 5000,
    newestOnTop: false,
    hideProgressBar: false,
  },
  mySiteUrl: "http://localhost:9332",
  langDebug: false,
  paypal: {
    client_id:
      "Afk7vWLUTzZk6FzcNxn5lYzxNPstSPBRumtFlFi5S72ZBBijtflYyCzuEsmPSoWGXeR3eyne7za0YPcT",
  },
};

const hostname = window && window.location && window.location.hostname;

if (hostname === "wanaid.org" || hostname === "www.wanaid.org") {
  appConfig = Object.assign({}, appConfig, {
    environment: "prod",
    gatewayRootUrl: "https://api.wanaid.org/api",
    googleTrackingId: "UA-186030314-1",
    googleTagManagerId: null,
    mySiteUrl: "https://my.wanaid.org",
    langDebug: false,
    paypal: {
      client_id:
        "AWwrJpDx9Y1bvZM9lr5dnpUo-raDbk3BkqMWtUzHt8InRZB_zN1HcOUJlZ8_24HItZ7B4oz0OQFzD3Iv",
    },
  });
} else if (hostname === "wanaid-staging.soiswis.net") {
  appConfig = Object.assign({}, appConfig, {
    environment: "staging",
    gatewayRootUrl: "https://wanaid-api-staging.soiswis.net/api",
    mySiteUrl: "https://my-wanaid-staging.soiswis.net",
    langDebug: false,
  });
} else if (hostname === "wanaid-staging-dev.soiswis.net") {
  appConfig = Object.assign({}, appConfig, {
    gatewayRootUrl: "https://wanaid-api-staging-dev.soiswis.net/api",
    mySiteUrl: "https://my-wanaid-staging-dev.soiswis.net",
  });
} else {
  appConfig = Object.assign({}, appConfig, {});
}
