import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// import Backend from 'i18next-chained-backend';
// import LocalStorageBackend from 'i18next-localstorage-backend';
// import HttpApi from 'i18next-http-backend';

import { appConfig } from "../../appConfig";

const savedLang = localStorage.getItem("lang")
  ? JSON.parse(localStorage.getItem("lang")).locale
  : "en";

// eslint-disable-next-line no-extend-native
String.prototype.toArabicDigits = function(lang) {
  if (lang === "ar") {
    var id = ["۰", "۱", "۲", "۳", "٤", "۵", "٦", "۷", "۸", "۹"];
    return this.replace(/[0-9]/g, function(w) {
      return id[+w];
    });
  } else {
    return this;
  }
};

// eslint-disable-next-line no-extend-native
String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: savedLang,
    backend: {
      loadPath: "/translations/{{lng}}.json?_t=" + new Date().getTime(),
      // loadPath: `${appConfig.gatewayRootUrl}/translate/{{lng}}`,
    },
    // backend: {
    //   backends: [
    //     LocalStorageBackend,  // primary
    //     HttpApi               // fallback
    //   ],
    //   backendOptions: [{
    //     projectId: new Date().getTime()
    //     /* below options */
    //   }, {
    //     loadPath: `${appConfig.gatewayRootUrl}/translate/{{lng}}?_t=${new Date().getTime()}`,
    //   }]
    // },
    fallbackLng: "en",
    debug: appConfig.langDebug,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: false,
      wait: false,
      bindStore: false,
      bindI18n: "languageChanged",
    },
  });

export default i18n;
