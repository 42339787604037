import React, {
  useEffect,
  // Fragment,
  useState,
  useLayoutEffect,
} from "react";

import { useSelector, useDispatch } from "react-redux";
import { appDataConstants, appMainConstants } from "./redux";

import "./lib/helpers/i18n";
import { useTranslation } from "react-i18next";

import { Switch, Route, withRouter, NavLink } from "react-router-dom";

import loadable from "@loadable/component";

import Favicon from "react-favicon";
import { appConfig } from "./appConfig";
import { AppService } from "./services/AppService";
import { ScrollTopButton } from "./components/common/extras/ScrollTopButton";
import { GoogleTracking } from "./components/google/GoogleTracking";
import { addRequest, removeRequest } from "./redux/actions";

import CookieConsent from "react-cookie-consent";
import { Fragment } from "react";

const Loading = (
  <div className="loading">
    <div className="text-center middle">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

const Footer = loadable(() => import("./components/common/Footer"), {
  fallback: Loading,
});
const TopNav = loadable(() => import("./components/common/TopNav"), {
  fallback: Loading,
});
const MainPreloader = loadable(
  () => import("./components/common/preloaders/MainPreloader"),
  {
    fallback: Loading,
  }
);
const Landing = loadable(() => import("./components/pages/landing/Landing"), {
  fallback: Loading,
});
const About = loadable(() => import("./components/pages/About"), {
  fallback: Loading,
});
const Contact = loadable(() => import("./components/pages/Contact"), {
  fallback: Loading,
});
const Clinic = loadable(() => import("./components/pages/Clinic"), {
  fallback: Loading,
});
const Gallery = loadable(() => import("./components/pages/Gallery"), {
  fallback: Loading,
});
const CausesList = loadable(() => import("./components/pages/causes/List"), {
  fallback: Loading,
});
const CauseDetails = loadable(
  () => import("./components/pages/causes/Details"),
  {
    fallback: Loading,
  }
);
const BlogDetails = loadable(() => import("./components/pages/blog/Details"), {
  fallback: Loading,
});
const TeamMemberDetails = loadable(
  () => import("./components/pages/team/Details"),
  {
    fallback: Loading,
  }
);
const Disclaimer = loadable(
  () => import("./components/pages/legal/Disclaimer"),
  {
    fallback: Loading,
  }
);
const PrivacyPolicy = loadable(
  () => import("./components/pages/legal/PrivacyPolicy"),
  {
    fallback: Loading,
  }
);
const TermsOfUse = loadable(
  () => import("./components/pages/legal/TermsOfUse"),
  {
    fallback: Loading,
  }
);
const ServerError = loadable(() => import("./components/errors/ServerError"), {
  fallback: Loading,
});
const NotFoundError = loadable(
  () => import("./components/errors/NotFoundError"),
  {
    fallback: Loading,
  }
);

function App(props) {
  const { t, i18n } = useTranslation();

  const [loadingError, setLoadingError] = useState(null);
  const appIsInitialised = useSelector((state) => state.main.appIsInitialised);
  const runningRequests = useSelector((state) => state.main.runningRequests);
  const availableLanguages = useSelector(
    (state) => state.main.availableLanguages
  );
  const defaultLanguage = useSelector((state) => state.main.defaultLanguage);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : defaultLanguage
  );
  const [locale, setLocale] = useState(selectedLang.locale);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(addRequest("LOADING_APPLICATION", "Loading website..."));
    dispatch(addRequest("LOADING_LANG_TRANSATION", "Loading translations..."));
  }, [dispatch]);

  useEffect(() => {
    if (!appIsInitialised) {
      AppService.initialize().then(
        (appData) => {
          dispatch(success(appData));
          dispatch(setIsInitialised());
          dispatch(removeRequest("LOADING_APPLICATION"));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(removeRequest("LOADING_APPLICATION"));
          setLoadingError(
            "Failed to initialise! Please check your internet connection"
          );
        }
      );
      function setIsInitialised() {
        return { type: appMainConstants.SET_IS_INITIALISED };
      }
      function success(appData) {
        return { type: appDataConstants.DATA_SUCCESS, payload: { appData } };
      }
      function failure(error) {
        return { type: appDataConstants.DATA_FAILURE, error };
      }
    }
  }, [dispatch, appIsInitialised]);

  useEffect(
    () =>
      props.history.listen((_location) => {
        GoogleTracking({
          ga_data: {
            type: "pageview",
            data: {
              path: _location.pathname,
            },
          },
        });
      }),
    [props.history]
  );

  useEffect(() => {
    // ^\/([\w]{2})\/
    const regExpStr = /^\/([\w]{2})\//gm;
    const currLang = window.location.pathname.match(new RegExp(regExpStr, "g"));

    if (currLang && currLang.length > 0) {
      const _lang = currLang[0].replace("/", "").replace("/", "");
      setLocale(_lang);
      i18n.changeLanguage(_lang).then((t) => {
        dispatch(removeRequest("LOADING_LANG_TRANSATION"));
        props.history.push(window.location.pathname.replaceAt(0, currLang[0]));
      });
    } else {
      props.history.push(
        window.location.pathname.replaceAt(0, `/${selectedLang.locale}/`)
      );
    }
  }, [dispatch, selectedLang, props.history, availableLanguages, i18n, locale]);

  useEffect(() => {
    if (typeof availableLanguages[locale] !== "undefined") {
      setSelectedLang(availableLanguages[locale]);
      localStorage.setItem("lang", JSON.stringify(selectedLang));
      document.body.classList.remove("rtl");
      document.body.classList.remove("ltr");
      document.body.classList.add(selectedLang["direction"]);
    }
  }, [availableLanguages, locale, selectedLang]);

  return (
    <Fragment>
      {/* <Link
        className="floating-btn btn btn-primary btn-lg rounded"
        to={`/${i18n.language}/cause/help-us`}
      >
        {t("OPEN_CAUSES_DONATE_NOW_LABEL")}
      </Link> */}
      <CookieConsent
        location="bottom"
        buttonText={t("WEBSITE_COOKIE_ALERT_MESSAGE_BTN_LABEL")}
        cookieName="saa-cookie-consent"
        style={{
          background: appConfig.colors.primary,
          borderTop: "1px solid " + appConfig.colors.secondary,
          fontSize: "13px",
        }}
        buttonStyle={{
          color: "#fff",
          background: appConfig.colors.secondary,
          fontSize: "13px",
        }}
        expires={150}
      >
        {t("WEBSITE_COOKIE_ALERT_MESSAGE")}{" "}
        <span>
          <NavLink
            to={`/${i18n.language}/privacy-policy`}
            style={{ color: "#eee" }}
          >
            {t("WEBSITE_COOKIE_ALERT_MESSAGE_PRIVACY_POLICY_LABEL")}
          </NavLink>
        </span>
      </CookieConsent>
      {loadingError ? (
        <div className="alert alert-danger init-fail">
          {t("WEBSITE_ERRORS_INITIALISATION_FAILED_MESSAGE")}
        </div>
      ) : null}
      <Favicon
        url={
          appConfig.environment !== "prod"
            ? "favicon-" + appConfig.environment + ".png?v=" + appConfig.version
            : "favicon.png?v=" + appConfig.version
        }
      />
      <MainPreloader show={Object.keys(runningRequests).length > 0} />
      <TopNav selectedLang={selectedLang} setSelectedLang={setSelectedLang} />

      <Switch>
        <Route exact path={`/${i18n.language}/`}>
          <Landing />
        </Route>
        <Route path={`/${i18n.language}/about`}>
          <About />
        </Route>
        <Route path={`/${i18n.language}/contact`}>
          <Contact />
        </Route>
        <Route path={`/${i18n.language}/marital-therapy-clinic`}>
          <Clinic />
        </Route>
        <Route path={`/${i18n.language}/gallery`}>
          <Gallery />
        </Route>
        <Route path={`/${i18n.language}/disclaimer`}>
          <Disclaimer />
        </Route>
        <Route path={`/${i18n.language}/privacy-policy`}>
          <PrivacyPolicy />
        </Route>
        <Route path={`/${i18n.language}/terms-of-use`}>
          <TermsOfUse />
        </Route>
        <Route path={`/${i18n.language}/causes/search`}>
          <CausesList />
        </Route>
        <Route path={`/${i18n.language}/cause/:title`}>
          <CauseDetails />
        </Route>
        <Route path={`/${i18n.language}/blog/:title`}>
          <BlogDetails />
        </Route>
        <Route path={`/${i18n.language}/team/:title`}>
          <TeamMemberDetails />
        </Route>
        {props.history.location.pathname !== `/${i18n.language}/login` &&
        props.history.location.pathname !== `/${i18n.language}/signup` ? (
          <Route exact path={`/${i18n.language}/:section`}>
            <Landing pathname={props.history.location.pathname} />
          </Route>
        ) : (
          ""
        )}
        <Route exact path={`/${i18n.language}/errors/500-server-error`}>
          <ServerError />
        </Route>
        <Route exact path={`/${i18n.language}/errors/404-page-not-found`}>
          <NotFoundError />
        </Route>
        {/* {secureRoutes} */}
      </Switch>
      <Footer />
      <ScrollTopButton />
    </Fragment>
  );
}

export default withRouter(App);
