import React, { Fragment, useState, useEffect } from "react";

export const ScrollTopButton = (props) => {
  const [btnVisible, setBtnVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onScroll = () => {
    const isVisible = window.scrollY > 100;
    if (isVisible !== btnVisible) {
      setBtnVisible(isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
  });

  return btnVisible ? (
    <button
      type="button"
      className={
        (props.customCssClass ? props.customCssClass : "") +
        " scroll-to-top rounded btn btn-primary"
      }
      onClick={scrollToTop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-chevron-up"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    </button>
  ) : (
    <Fragment />
  );
};
