import {
  appMainConstants,
  preloaderConstants,
  appDataConstants,
  ajaxRequestsConstants,
} from "../constants";
const initialState = {
  isInitialised: false,
  isLoading: true,
  runningRequests: {},
  defaultLanguage: {
    country_code: "GB",
    locale: "en",
    name: "English",
    direction: "ltr",
  },
  availableLanguages: {
    en: {
      country_code: "GB",
      locale: "en",
      name: "English",
      direction: "ltr",
    },
  },
  currencies: {
    default: {
      code: "USD",
      default: 1,
      name: "United States dollar",
    },
    available: [
      {
        code: "USD",
        default: 1,
        name: "United States dollar",
      },
    ],
  },
  open_causes: [],
  blogs: [],
  gallery_count: 0,
  categories: [],
  categories_search: {
    type: "list",
  },
  settings: {},
  pageContents: {
    LANDING: {
      HEADER: {
        ctas: [],
      },
      PILLARS: [],
    },
    COMMON: {
      FEATURES: {},
    },
  },
};

export const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case appMainConstants.SET_IS_INITIALISED:
      return { ...state, isInitialised: true };
    case preloaderConstants.SHOW_INITIALISING_PRELOADER:
      return { ...state, isLoading: true };
    case preloaderConstants.HIDE_INITIALISING_PRELOADER:
      return { ...state, isLoading: false };
    case appDataConstants.DATA_SUCCESS:
      return {
        ...state,
        defaultLanguage: payload.appData.languages.default,
        availableLanguages: payload.appData.languages.data,
        currencies: payload.appData.currencies,
        blogs: payload.appData.blogs,
        gallery_count: payload.appData.gallery_count,
        open_causes: payload.appData.open_causes,
        categories: payload.appData.categories,
        settings: payload.appData.settings,
        pageContents: payload.appData.pageContents,
        loggedInUser: payload.appData.loggedInUser,
      };
    case appDataConstants.DATA_FAILURE:
      return { ...state, isLoading: false };
    case ajaxRequestsConstants.ADD_REQUEST:
      const addRunningRequests = {
        ...state.runningRequests,
        [payload.key]: payload.description,
      };
      return { ...state, runningRequests: addRunningRequests };
    case ajaxRequestsConstants.REMOVE_REQUEST:
      const removePropery = (propKey, { [propKey]: propValue, ...rest }) =>
        rest;
      const removeRunningRequests = removePropery(
        payload.key,
        state.runningRequests
      );
      return { ...state, runningRequests: removeRunningRequests };
    default:
      return state;
  }
};
